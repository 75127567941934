import React from 'react';
import {
  Box,
  Grid,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  box: {
    padding: theme.spacing(2, 0),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.neutrals.high.light
  },
  title: {
    padding: theme.spacing(4, 0),
    color: theme.palette.neutrals.low.main
  },
  subTitle: {
    fontWeight: 800,
    paddingBottom: theme.spacing(2),
    color: theme.palette.neutrals.low.light
  },
  description: {
    fontSize: 15,
    lineHeight: 1.8,
    paddingBottom: theme.spacing(2),
    color: theme.palette.neutrals.low.light
  },
  link: {
    transition: '0.5s ease-in-out',
    color: theme.palette.neutrals.low.light,
    '&:hover': {
      color: theme.palette.neutrals.low.main
    }
  }
}));

export function PrivacyPolicyPage() {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Typography variant="h3" className={classes.title}>
        AVISO DE PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS DO GRUPO DEXCO
      </Typography>

      <Typography variant="h6" className={classes.subTitle}>
        PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS NO IMAGINE
      </Typography>

      <Typography variant="body1" className={classes.description}>
        Os sites da Dexco conectam você (Titular), às diversas soluções em suas linhas de negócio tais como Duratex Madeira, Durafloor, Ceusa, Portinari, DEXperience, Hydra, Castelatto e Deca.
        <br/>Esse aviso descreve as formas de utilização de seus dados pessoais e os seus direitos como Titular, bem como todos os assuntos descritos abaixo.
      </Typography>

      <Typography variant="h6" className={classes.subTitle}>
        Dados Tratados no Grupo Dexco
      </Typography>

      <Typography variant="body1" className={classes.description}>
        Ao interagir conosco alguns de seus dados pessoais podem ser coletados ou fornecidos por você. Nosso compromisso é trazer transparência e demonstrar o uso ético dos seus dados pessoais.
      </Typography>

      <Typography variant="body1" className={classes.description}>
        A Dexco pode compartilhar seus dados pessoais com parceiros comerciais para prestação dos serviços ou execução de atividades comerciais, mas também com autoridades e demais interessados, para cumprimento de obrigações legais, proteção de seus interesses, ou mediante ordem judicial, pelo requerimento de autoridades administrativas. Todos os parceiros da Dexco passam por uma análise para assegurar medidas de proteção adequadas para o tratamento de seus dados.
      </Typography>

      <Typography variant="h6" className={classes.subTitle}>
        DADOS PESSOAIS NO IMAGINE
      </Typography>

      <Typography variant="body1" className={classes.description}>
        Abaixo esclarecemos como e quais dados são utilizados internamente, com relação a este site:
      </Typography>

      <Box className={classes.box}>
        <Typography variant="h6" className={classes.subTitle}>
          Imagine
        </Typography>
        <Typography variant="body1" className={classes.description}>
          https://imagine.dex.co
        </Typography>
        <Typography variant="h6" className={classes.subTitle}>
          Quais dados pessoais são utilizados?
        </Typography>
        <Typography variant="body1" className={classes.description}>
          Matrícula, e-mail,, nome completo, data de nascimento, telefone, unidade de negócio, local, área, função, biografia, foto, pontuação, celular, endereço completo de entrega, destinatário e dados de cartão de crédito de colaboradores Dexco.
        </Typography>
        <Typography variant="h6" className={classes.subTitle}>
          Para que utilizamos seus dados pessoais?
        </Typography>
        <Typography variant="body1" className={classes.description}>
          Realização de cadastro, permitindo que: tenha acesso à plataforma, consiga fazer login, bem como editar seus dados pessoais, enviar ideias, sugestões e melhorias nos processos da empresa, passando pela avaliação do gestor responsável, e possibilitando o recebimento de pontos via plataforma Imagine, com posterior resgate de prêmios, para identificação do participante no ranking de pontuações e para o resgate de prêmios.
        </Typography>
        <Typography variant="h6" className={classes.subTitle}>
          Com quem compartilhamos seus dados pessoais?
        </Typography>
        <Typography variant="body1" className={classes.description}>
          Compartilhamos os seus dados pessoais com os seguintes terceiros, ou similares contratados por ela, para as finalidades descritas abaixo:<br />
          - Amazon Web Services e Azure [EUA]: Infraestrutura tecnológica e armazenamento de dados [serviços de nuvem | cloud]<br />
          - Braspag: Meio de pagamento, bem como para realização do débito no cartão de crédito do colaborador, caso queira complementar a pontuação para o resgate;<br />
          - FCamara: Acesso e fornecimento de informações e relatórios à Área de Negócio Dexco responsável pelo processo;<br />
          - Parceiros Premmiar: Entrega e emissão de nota fiscal. Cada parceiro possui uma regra específica sendo que, maiores esclarecimentos podem ser encontrados na Política de Privacidade da empresa Premmiar, responsável pelo respectivo compartilhamento. Poderão ser compartilhados maior ou menor quantidade de dados pessoais, a depender do parceiro;<br />
          - Premmiar: Controle da pontuação e recarga de créditos para que o colaborador consiga resgatar os prêmios a que tiver direito; e<br />
          - Securiti.ai: Ferramenta para gestão e operacionalização do Programa de Privacidade Dexco.

        </Typography>
        <Typography variant="h6" className={classes.subTitle}>
          Fundamento legal:
        </Typography>
        <Typography variant="body1" className={classes.description}>
          Consentimento
        </Typography>
      </Box>



      <Typography variant="h6" className={classes.subTitle}>
        Conservação e Eliminação de Dados
      </Typography>
      <Typography variant="body1" className={classes.description}>
      Manteremos seus dados pessoais armazenados enquanto for necessário, para prestar serviços, para fins legítimos, considerando o prazo de até 10 anos, e para cumprimento de obrigações legais. Conservamos alguns dos seus dados pessoais enquanto seu cadastro permanecer ativo nos portais do Grupo Dexco.  
      </Typography>
      <Typography variant="body1" className={classes.description}>
        Quando solicitado, eliminaremos os seus dados pessoais, exceto se for legalmente permitido, incluindo situações como as seguintes: 
      </Typography>
      <Typography variant="body1" className={classes.description}>
        •	Se formos obrigados a manter os dados pessoais para as nossas obrigações legais, jurídicas, fiscais, de auditoria e contabilidade, mantendo apenas pelo período exigido pela legislação aplicável; ou 
        <br />
        •	Sempre que necessário para a prevenção de fraudes ou para manter a segurança do nosso ambiente e demais usuários do portal/site. 
      </Typography>

      <Typography variant="h6" className={classes.subTitle}>
        Gestão de Cookies
      </Typography>
      <Typography variant="body1" className={classes.description}>
        Cookies são arquivos ou informações que podem ser armazenadas em seus dispositivos, quando você visita o site. A Dexco utiliza cookies para facilitar o uso e melhor adaptar seus sites e aplicativos aos interesses e necessidades de cada usuário, sendo dividido em quatro categorias:
      </Typography>

      {/*  */}
      <Grid container>
        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Necessários:
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
            Esses cookies são essenciais para que os sites e aplicativos carreguem corretamente e permitam que você navegue e faça uso de todas as funcionalidades disponíveis.
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Desempenho
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
            Esses cookies nos ajudam a entender como os visitantes interagem com sites e aplicativos, fornecendo informações sobre as áreas visitadas, o tempo de visita e quaisquer problemas encontrados, como mensagens de erro.
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Funcionais
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
            Esses cookies permitem que sites e aplicativos se lembrem das escolhas dos usuários, para proporcionar uma experiência mais personalizada. Também, possibilitam que os usuários utilizem a integração com redes sociais, campos para comentários, entre outros.
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Marketing
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" className={classes.description}>
            Esses cookies são utilizados para fornecer mais conteúdo relevante e do interesse dos usuários. Podem ser utilizados para apresentar publicidade direcionada ou limitar sua veiculação.
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="body1" className={classes.description}>
        Ao logar pela primeira vez em nossos sites ou aplicativos, você poderá gerenciar os cookies, por meio do banner de preferências.
      </Typography>

      <Typography variant="body1" className={classes.description}>
        A qualquer momento, você poderá rever a forma de aplicação dos cookies, através das configurações de seu navegador de preferência.  
      </Typography>

      <Typography variant="body1" className={classes.description}>
        Caso você não permita a utilização de alguns cookies, certos serviços poderão não funcionar de maneira adequada. 
      </Typography>

      <Typography variant="h6" className={classes.subTitle}>
        Segurança da informação e dos dados pessoais
      </Typography>
      <Typography variant="body1" className={classes.description}>
        A Dexco adota medidas de segurança para manter a confidencialidade, a integridade, a disponibilidade e autenticidade das informações e dos dados pessoais em seus sites e aplicativos, incluindo as diretrizes sobre padrões de segurança estabelecidas nas legislações e regulamentações aplicáveis, tais como métodos de criptografia de dados, proteção contra acessos não autorizados, entre outros.
      </Typography>
      <Typography variant="body1" className={classes.description}>
        A Dexco coleta e armazena pelo período necessário os dados de acesso aos seus sites e aplicativos (data, hora e IP), para cumprimento de obrigação legal imposta pelo Marco Civil da Internet (art. 15, Lei nº 12.965/2014). 
      </Typography>
      <Typography variant="body1" className={classes.description}>
        Apesar de todas as medidas de segurança que adotamos, é importante lembrar que nenhum site ou aplicativo é completamente imune a riscos. Por isso, recomendamos fortemente que você também adote práticas de segurança para proteger seus dados pessoais. Algumas dessas práticas incluem manter seus logins e senhas em sigilo, utilizar senhas fortes e únicas para cada serviço, ativar a autenticação em duas etapas sempre que possível, e estar atento a possíveis tentativas de phishing ou outras formas de fraude online. 
      </Typography>

      <Typography variant="h6" className={classes.subTitle}>
        Seus direitos
      </Typography>
      <Typography variant="body1" className={classes.description}>
        As leis brasileiras de privacidade e proteção de dados pessoais asseguram a você, titular de dados pessoais, uma série de garantias que o colocam no controle das informações que você nos fornece. Como modo de informá-lo sobre os seus Direitos e como exercê-los, apresentamos a seguir tudo aquilo que se aplica a você.
        <br />

        Para exercer esses direitos, você pode solicitá-los à Dexco, por meio {' '}
        <a
          href="https://privacy-central.securiti.ai/#/dsr/c261c14c-8c17-4e1c-96ab-7029d8b2b485"
          target="_blank"
          className={classes.link}
        >
          do Portal de Abertura de Solicitações de Titulares (DSR). 
        </a>


        <br />
        Importante ressaltar que representantes legais poderão exercer os direitos em nome do titular, desde que comprovados os poderes para essa atividade. 
        <br />
        Conforme previsto na LGPD, você pode exercer os seguintes direitos com relação aos seus dados pessoais: 
        <br />
        •	Confirmar a existência de tratamento: este direito permite que o titular de dados requisite e receba uma confirmação sobre a existência de coleta, armazenamento, compartilhamento ou qualquer tipo de tratamento a partir de seus dados;
        <br />
        •	Dar acesso aos dados: este direito permite que o titular de dados requisite e receba uma cópia gratuita e em formato acessível dos dados tratados;
        <br />
        •	Corrigir dados incompletos, inexatos ou desatualizados: este direito permite que o titular de dados requisite a retificação de seus dados;
        <br />
        •	Anonimizar, bloquear ou eliminar dados desnecessários, excessivos ou tratados em desconformidade com a LGPD: este direito permite que o titular de dados requisite a anonimização, o bloqueio ou a eliminação dos dados da base da Dexco. Os dados tratados serão anonimizados, bloqueados ou eliminados dos servidores e arquivos físicos da Dexco, quando assim for possível e requisitado pelo titular e, em conformidade com o atendimento a outras legislações vigentes aplicáveis;
        <br />
        •	Enviar dados a outro fornecedor de serviços e/ou produto, mediante requisição expressa, observados os segredos comercial e industrial: este direito permite que o titular de dados requisite a transferência dos dados tratados à outra parte, em formato estruturado e interoperável;
        <br />
        •	Obter informações sobre o compartilhamento de seus dados: este direito permite que o titular de dados requisite com quais entidades públicas e privadas seus dados são compartilhados;
        <br />

        •	Peticionar contra o Controlador: este direito permite que o titular de dados requisite auxílio à ANPD, caso não consiga exercer seus direitos perante o Controlador;

        <br />
        •	Revisar e fornecer informações sobre as decisões tomadas unicamente com base em tratamento automatizado de dados que afetem seus interesses, incluídas as decisões destinadas a definir o seu perfil pessoal, profissional, de consumo e de crédito ou os aspectos de sua personalidade: este direito permite que o titular de dados requisite a revisão de decisões tomadas unicamente com base em tratamento automatizado dos dados e receba informações claras e adequadas a respeito dos critérios utilizados nas decisões, desde que estas não violem qualquer segredo comercial da Companhia; e
        <br />
        •	Não realizar tratamento com fundamento em uma das hipóteses de dispensa de consentimento: este direito permite que o titular de dados se oponha a usos de seus dados pessoais que dispensam consentimento.
        <br />
        Ainda, te informamos que nosso time retornará a você com uma resposta dentro do prazo legal, a partir do recebimento da requisição. Se verificada a necessidade de esclarecimentos complementares, os prazos legais de atendimento estarão suspensos até o recebimento da sua resposta. 

      </Typography>


      <Typography variant="h6" className={classes.subTitle}>
        Encarregado pelo Tratamento de Dados Pessoais
      </Typography>
      <Typography variant="body1" className={classes.description}>
      A Dexco tem em sua estrutura um Encarregado pelo Tratamento de Dados Pessoais, Gustavo Conte Jakovac, precisamente para garantir que tenhamos sempre as melhores diretrizes e orientações sobre o tratamento de dados pessoais.  
        <br />Ele é o canal de comunicação sobre os temas de privacidade e proteção de dados com as autoridades competentes e com você. Por isso, caso tenha alguma dúvida, sugestão ou solicitação, entre em contato no e-mail dpo@dex.co.

      </Typography>

      <Typography variant="h6" className={classes.subTitle}>
        Atualizações desta Política
      </Typography>
      <Typography variant="body1" className={classes.description}>
        A Dexco manterá esse Aviso sempre atualizado, para que ele reflita a realidade sobre o tratamento de seus dados pessoais em nossos sites e aplicativos.
      </Typography>
      <Typography variant="body1" className={classes.description}>
        Última atualização em 09/12/2024
      </Typography>

      <Typography variant="body1" className={classes.description}>
        Endereço: Av. Paulista – 1938 – Bela Vista <br />
        São Paulo – SP CEP: 01310-942 <br />
        Telefone: (11) 3179-7733 <br />
        CNPJ: 97.837.181/0001-47 <br />
        Inscrição Estadual: 114.578.650.114
      </Typography>
    </Container>
  );
}